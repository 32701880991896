<template>
  <div class="chat-wrapper">
    <h3 class="green">Messages</h3>
    <hr />
    <div>
      <div class="chat-window">
        <div class="chat-content" ref="chatContent" v-on:scroll="scrollTop">
          <div
            v-if="chat && chat.length"
            class="chat-scroller"
            ref="chatContainer"
          >
            <div v-for="(msg, i) in chat" :key="i">
              <div v-if="msg.admin_user" class="chat-container chat-admin">
                <div class="chat-avatar">
                  <img
                    v-if="msg.admin_user.avatar"
                    :src="`${msg.admin_user.avatar}`"
                    alt=""
                  />
                  <span v-else> A</span>
                </div>

                <div class="chat-text">
                  <div class="chat-text-details">
                    <p
                      v-if="msg.admin_user.name"
                      class="chat-text-details-name grey"
                    >
                      {{ msg.admin_user.name }}
                    </p>
                    <p
                      v-if="msg.created_at"
                      class="chat-text-details-date grey"
                    >
                      {{ getTime(msg.created_at) }}
                    </p>
                  </div>
                  <p class="chat-text-area">{{ msg.content }}</p>
                </div>
              </div>

              <div v-else-if="msg.user" class="chat-container chat-user">
                <div class="chat-text">
                  <div class="chat-text-details">
                    <p class="chat-text-details-name green">
                      {{ msg.user.name }}
                    </p>
                    <p class="chat-text-details-date grey">
                      {{ getTime(msg.created_at) }}
                    </p>
                  </div>
                  <p class="chat-text-area">{{ msg.content }}</p>
                </div>

                <div class="chat-avatar">
                  <img
                    v-if="msg.user.avatar"
                    :src="`${msg.user.avatar}`"
                    alt=""
                  />
                  <span v-else>U</span>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="chat-text default">
            <h2 class="green center">Hello!</h2>
            <h4 class="grey center placeholder">
              If you have new questions, write us and our admin will answer you
            </h4>
          </div>
        </div>

        <div class="chat-action">
          <textarea
            v-model="message"
            class="chat-textarea"
            rows="3"
            @keyup.enter="sendMessage"
          />
          <button
            class="chat-btn"
            :disabled="!message || btnDisabled"
            @click="sendMessage"
          >
            <IconAirplane icon-color="#ffffff" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import IconAirplane from "@/modules/components/icons/IconAirplane.vue";

export default {
  components: { IconAirplane },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const store = useStore();
    const { id } = toRefs(props);

    store.dispatch("chat/clean");

    const chat = computed(() => store.getters["chat/chatList"]);
    const chatNextPage = computed(() => store.getters["chat/chatNextPage"]);
    const user = computed(() => store.getters["auth/user"]);
    const project = computed(() => store.getters["projects/project"]);
    const chatContent = ref(null);
    const message = ref("");
    const btnDisabled = ref(false);
    const allowedToLoadNext = ref(false);

    watch(chat, (newVal) => {
      if (newVal) {
        setTimeout(() => {
          scrollToEnd();
        }, 0);
      }
    });

    const getTime = (date) => {
      const formatedData = new Date(date);
      let hours = formatedData.getHours();
      let minutes = formatedData.getMinutes();
      let month = formatedData.getMonth();
      let day = formatedData.getDay();
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return `${day}.${month} ${hours}:${minutes}`;
    };

    const client = new WebSocket(
      "wss://user.cureline-crm.cureline.com/ws/" +
        localStorage.getItem("authTokenUser") +
        "/" +
        id.value +
        "/" +
        user.value.id
    );
    client.onmessage = function (event) {
      store
        .dispatch("chat/showMessage", JSON.parse(event.data))
        .then(() => scrollToEnd());
    };

    const sendMessage = async () => {
      if (message.value) {
        btnDisabled.value = true;
        let msg = {
          content: message.value,
          project_id: id.value,
          user_id: user.value.id,

          user: {
            id: user.value.id,
            avatar: user.value.avatar,
            name: user.value.name,
          },
          created_at: new Date().toISOString(),
          admin_user: null,
        };
        message.value = "";
        client.send(JSON.stringify(msg));

        await store.dispatch("chat/sendMessage", msg);
        scrollToEnd();
      }
      btnDisabled.value = false;
    };

    const scrollToEnd = () => {
      chatContent.value.scrollTop = chatContent.value.scrollHeight + 100;
    };

    const scrollTop = (ev) => {
      if (ev.srcElement.scrollTop > 2) {
        allowedToLoadNext.value = true;
      }
      if (
        chatNextPage.value &&
        !ev.srcElement.scrollTop &&
        allowedToLoadNext.value
      ) {
        store.dispatch(
          "chat/getChat",
          { id: project.value.id, page: chatNextPage.value },
          { root: true }
        );
      }
    };

    return {
      user,
      chat,
      chatContent,
      message,
      btnDisabled,
      getTime,
      sendMessage,
      scrollTop,
    };
  },
};
</script>

<style lang="scss" scoped>
.chat {
  &-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding-top: 20px;

    &.default {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .placeholder {
      max-width: 180px;
      margin: 0 auto;
      opacity: 0.4;
    }
  }

  &-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 auto;
    min-height: 360px;
    height: calc(100vh - 600px);
    overflow-y: auto;
    padding-right: 10px;
    margin-bottom: 10px;

    &-scroller {
      min-height: 360px;
      max-height: 360px;
      margin-bottom: 20px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-avatar {
    min-width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 28px;
    border: none;
    border-radius: 50%;
    color: var(--col-info);
    background-color: var(--col-info-bg);
    overflow: hidden;
  }

  &-text {
    flex-grow: 1;
    padding: 5px;

    &.default {
      margin-top: 50px;
    }

    &-details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-name {
        font-size: 14px;
      }

      &-date {
        font-size: 10px;
      }
    }

    &-area {
      padding: 5px;
      border: 1px solid var(--col-info-bg);
      border-radius: 8px;
      line-height: 1.2;
      background-color: var(--col-info-bg);
    }
  }

  &-user {
    .chat-text {
      padding-left: 50px;
      &-details {
        &-name {
          padding-left: 10px;
        }
      }

      &-area {
        background-color: var(--col-contrast-text);
      }
    }
  }

  &-admin {
    .chat-text {
      padding-right: 50px;
      &-details {
        &-date {
          padding-right: 10px;
        }
      }
    }
  }

  &-action {
    position: relative;
  }

  &-textarea {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 1px solid #c8c8cc;
    border-radius: 14px;
    padding: 5px;
    padding-right: 30px;
    line-height: 1.2;
    color: var(--col-primary-dark);
    resize: none;
    overflow: auto;
  }

  &-btn {
    position: absolute;
    right: 4px;
    bottom: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 27px;
    height: 27px;
    border: none;
    border-radius: 50%;
    background-color: var(--col-info);

    &:disabled {
      background-color: var(--col-info-bg);
      cursor: unset;
    }
  }
}
</style>
